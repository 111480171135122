<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.VelocityFactorPerDeveloper]"
    :tooltip="chartExplanations[chartTypes.VelocityFactorPerDeveloper]"
    :fetch-error="fetchError"
    :loading="loading"
    :is-chart-data-empty="isEmpty(velocityFactorPerDeveloper)"
    :type="chartTypes.VelocityFactorPerDeveloper"
    :icons="['jira']"
    :custom-height="customHeight ? '404px' : ''"
  >
    <MyTable
      class="overflow-hidden"
      :table-data="velocityFactorPerDeveloper"
      :columns-data="columnsData"
      :scrollable="scrollableTable"
      :custom-height="customHeight ? '368px' : '300px'"
    >
      <template #name="{ data }">
        <div>
          {{ isAggregationByProjects ? data.project.name : data.user.name }}
        </div>
      </template>
      <template #velocity_factor="{ data }">
        <div class="flex items-center">
          <div>
            {{ +data.velocity_factor.toFixed(2) }}
          </div>
          <i
            v-if="!data.velocity_factor"
            class="pi pi-info-circle pl-2 pr-4 text-gray-500"
            style="font-size: 12px"
            aria-hidden="true"
            v-tooltip.bottom="{
              value:
                'Value is 0, because hours are not logged or estimation is not provided',
              class: 'max-width-tooltip',
            }"
          ></i>
        </div>
      </template>
    </MyTable>
  </ChartWrapper>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, withDefaults } from 'vue'
import { Filters } from '@/store/modules/filters'
import { isEmpty, omit } from 'ramda'
import MyTable from '@/components/common/table/MyTable.vue'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import {
  chartTypes,
  chartTitle,
  chartExplanations,
} from '@/constants/charts/constants'
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import { Aggregate_By_Type } from '@/types/types'
import { AGGREGATE_BY } from '@/constants/constants'

const props = withDefaults(
  defineProps<{
    filters: Filters
    aggregation?: Aggregate_By_Type
    customHeight?: boolean
  }>(),
  { aggregation: 'users' }
)

const scrollableTable = ref(true)

const isAggregationByProjects = computed(
  () => props.aggregation === AGGREGATE_BY.PROJECTS
)

const { response, loading, fetchError } = useGettingChartData(
  {
    ...props,
    filters: {
      ...omit(['scale_type'], props.filters),
      aggregate_by: isAggregationByProjects.value
        ? AGGREGATE_BY.PROJECTS
        : AGGREGATE_BY.USERS,
    },
  },
  chartTypes.VelocityFactor
)

const velocityFactorPerDeveloper = computed(
  () => response.value?.data.data || []
)

const columnsData = [
  {
    header: 'Name',
    field: 'name',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:2; padding-right: 10px',
  },
  {
    header: 'Velocity factor',
    field: 'velocity_factor',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:1; min-width: 150px; margin-right: 20px',
  },
]
</script>

<style lang="scss" scoped>
.p-datatable .p-virtualscroller-content {
  max-width: 100%;
}
</style>
