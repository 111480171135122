<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="onCloseDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        {{ details?.label }}
        <TableSearchInput
          :value="search"
          @update:value="search = $event || null"
          placeholder="Search by ticket..."
        ></TableSearchInput>
      </div>
    </template>
    <div class="mb-2 flex items-start justify-between">
      <div class="text-xl font-semi-bold mb-4">{{ title }}</div>
      <div class="flex items-center mt-1">
        <div class="text-black text-base mr-2">Data:</div>
        <BaseSelectButton
          v-model:selection="historical"
          :options="historicalOptions"
        ></BaseSelectButton>
      </div>
    </div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <div v-else>
      <MyTable
        :table-data="dotData"
        :columns-data="columnsData"
        customHeight="70vh"
        :scrollable="scrollableTable"
        class="mb-8"
        lazy-table
        :lazy-loading="lazyLoading"
        @load="onLazyLoad"
        @sort="onSort"
      >
        <template #issue_key="{ data }">
          <Link :href="data.absolute_url" style-class="truncate">
            {{ `${data.issue_key}: ${data.issue_name}` }}
          </Link>
        </template>
        <template #adaptive_estimate="{ data }">
          {{
            getEstimationReadableString(data.adaptive_estimate, estimationType)
          }}
        </template>
        <template #status="{ data }">
          <Chip
            :label="data.status"
            :color="chipBackground(data.status)"
          ></Chip>
        </template>
      </MyTable>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import Chip from '@/components/common/Chip.vue'
import {
  computed,
  defineEmits,
  defineProps,
  ref,
  watch,
  watchEffect,
} from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import {
  calcSortOrderingAndField,
  getEstimationReadableString,
  showToastError,
} from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, omit, reject } from 'ramda'
import { format } from 'date-fns'
import Link from '@/components/common/Link.vue'
import { debounce } from 'lodash'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import {
  DONE_STATUSES,
  ESTIMATION_TYPE,
  TO_DO_STATUSES,
} from '@/constants/constants'
import BaseSelectButton from '@/components/common/base/BaseSelectButton.vue'

const props = defineProps<{
  open: boolean
  details: {
    date?: string
    year: string
    week?: string
    month?: string
    scale_type: string
    line_type: string
    label: string
  } | null
  labels: number[] | null
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const estimationType = ref<ESTIMATION_TYPE | null>(ESTIMATION_TYPE.STORY_POINTS)
const dotData = ref([])
const nextPage = ref(null)
const lazyLoading = ref(false)
const loading = ref(false)
const sortField = ref<string | null>(null)
const search = ref(null)
const store = useStore()
const toast = useToast()
const historical = ref(
  props.details?.line_type === 'in_progress_adaptive_estimates'
)

const historicalOptions = [
  { name: 'Historical', value: true },
  { name: 'Current', value: false },
]

const scrollableTable = ref(true)
const detailsValue = computed(() => props.details)
const projectId = computed(() => store.state.filters.projectFilters.projects)

const debouncedGetRequest = debounce(getFirstPageOfDotDetails, 1000)

watchEffect(() => {
  historical.value =
    props.details?.line_type === 'in_progress_adaptive_estimates'
})

watch([sortField, search, detailsValue, historical], () => {
  if (search.value) {
    debouncedGetRequest()
  } else {
    detailsValue.value && getFirstPageOfDotDetails()
  }
})

const title = computed(() => {
  if (!props.details) return ''
  const { week, date, month, year } = props.details
  let formatDate
  if (date) {
    formatDate = format(new Date(date), 'd MMMM yyyy')
  } else if (week) {
    formatDate = `week ${week} of ${year}`
  } else {
    formatDate = month && format(new Date(+year, +month - 1, 1), 'MMMM, yyyy')
  }
  return formatDate
})

const chipBackground = (status: string) => {
  if (TO_DO_STATUSES.includes(status)) {
    return 'bg-gray-100-60'
  } else if (DONE_STATUSES.includes(status)) {
    return 'bg-success-50'
  } else {
    return 'bg-key-100'
  }
}

const columnsData = [
  {
    header: 'Ticket',
    field: 'issue_key',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow: 2.5; padding-right: 10px',
  },
  {
    header: 'Original estimation',
    field: 'adaptive_estimate',
    is_sortable: true,
    use_template: true,
    styles: 'padding-right: 10px',
  },
  {
    header: 'Jira Status',
    field: 'status',
    is_sortable: true,
    use_template: true,
  },
]

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  const { last } = event
  if (!last || lazyLoading.value) return
  try {
    if (nextPage.value && last === dotData.value.length) {
      lazyLoading.value = true
      const page = await store.dispatch(
        'scopeVsDelivered/getNextPageOfDotDetails',
        nextPage.value
      )
      nextPage.value = page.next
      dotData.value = dotData.value.concat(page.results.data)
      estimationType.value = page?.results.estimation_type
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

function onSort(event: Event) {
  sortField.value = calcSortOrderingAndField(event)
}

async function getFirstPageOfDotDetails() {
  const params = {
    ...omit(['label'], props.details),
    historical: `${historical.value}`,
    ordering: sortField.value,
    search: search.value,
    labels: props.labels,
  }
  const activeParams = {
    ...reject(equals(null))(params as Dictionary<any>),
  }
  if (activeParams.search || activeParams.ordering) {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  try {
    const page = await store.dispatch('scopeVsDelivered/getDotDetails', {
      ...activeParams,
      id: projectId.value,
    })
    nextPage.value = page?.next
    dotData.value = page?.results.data
    estimationType.value = page?.results.estimation_type
  } catch (e) {
    showToastError(toast, e)
  } finally {
    lazyLoading.value = false
    loading.value = false
  }
}

const onCloseDialog = () => {
  emit('close')
  search.value = null
  sortField.value = null
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
.height {
  height: 70vh;
}
</style>
