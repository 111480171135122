<template>
  <div
    class="cell"
    :class="cellClasses"
    @click="!projectPage && $emit('show-calendar-popup', bodyData[date])"
  >
    <div
      v-if="isReleasesShown"
      class="releases flex pl-2 pr-2"
      v-tooltip="{
        escape: true,
        value: getReleasesTooltipContent(releases),
      }"
      @click.stop="clickOnReleases($event)"
    >
      <img
        v-for="release of releases"
        :key="release.id"
        :src="require('../../../assets/icons/milestone-release-dot.svg')"
        alt="Release Icon"
        class="release-dot"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'
import { isSameWeek } from 'date-fns'
import {
  milestoneCellBorder,
  milestonePlanningBackground,
  milestoneStatusCellBackground,
} from '@/utils/chart-utils'
import { MilestoneReleaseItem } from '@/store/modules/charts/milestones'
import { DateColumnData } from '@/components/charts/milestones/MilestonesTable.vue'

const props = defineProps<{
  projectPage?: boolean
  bodyData: any
  date: string
}>()

const emit = defineEmits<{
  (e: 'show-calendar-popup', data: DateColumnData): void
  (
    e: 'click-on-releases',
    data: { event: Event; releases: MilestoneReleaseItem[] }
  ): void
}>()

const releases = computed(() =>
  props.projectPage
    ? [props.bodyData.release]
    : props.bodyData[props.date].releases
)

const cellClasses = computed(() =>
  !props.projectPage
    ? [
        milestonePlanningBackground(props.bodyData[props.date].plans),
        milestoneCellBorder(props.bodyData[props.date].weekly_reports?.status),
      ]
    : [
        milestoneStatusCellBackground(
          props.bodyData[props.date].weekly_reports?.status
        ),
      ]
)

const isReleasesShown = computed(
  () =>
    (!props.projectPage && props.bodyData[props.date].releases?.length) ||
    (props.projectPage &&
      isSameWeek(new Date(props.bodyData.release.date), new Date(props.date)))
)

const getReleasesTooltipContent = (releases: MilestoneReleaseItem[]) => {
  if (!releases) return ''
  const releasesList = releases
    .map((release) => {
      return `<div>${release.name}</div>`
    })
    .join('\n')
  return `<div class="flex flex-col">${releasesList}</div>`
}

const clickOnReleases = (event: Event) => {
  emit('click-on-releases', {
    event,
    releases: releases.value,
  })
}
</script>
