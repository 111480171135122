<template>
  <IconButton
    v-tooltip.left="
      'This filter affects only Scope vs delivered, Budget planned vs used and Burndown charts'
    "
    icon="pi-chevron-down"
    icon-position="right"
    icon-size="12px"
    class="p-button-text"
    v-bind="$attrs"
    @click="toggleOverlayPanel"
  >
    {{ AGGREGATION_TYPE_TITLES[scaleType?.toUpperCase()] }}
  </IconButton>
  <OverlayPanel ref="panel" id="panel">
    <div class="w-full p-1">
      <SingleFilterButton
        v-for="type of aggregationTypeOptions"
        :key="type.value"
        :title="type.text"
        :selected="type.value === scaleType"
        :disabled="type.disabled"
        @click="onChangeAggregation(type.value)"
      ></SingleFilterButton>
    </div>
  </OverlayPanel>
</template>
<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { ref, defineProps, defineEmits, computed, watchEffect } from 'vue'
import {
  AGGREGATION_TYPE,
  AGGREGATION_TYPE_TITLES,
} from '@/constants/constants'
import SingleFilterButton from '@/components/common/filters/local/SingleFilterButton.vue'
import { TimeFilters } from '@/store/modules/filters'
import { calcAggregationAccordingToTimeFilters } from '@/utils/date-utils'

const props = defineProps<{
  scaleType?: string
  timeFilters: TimeFilters
  smallSize?: boolean
}>()
const emit = defineEmits<{
  (e: 'change', value: string): void
}>()

const panel = ref()

const toggleOverlayPanel = (event: Event) => {
  panel.value.toggle(event)
}

watchEffect(() => {
  if (
    calcAggregationAccordingToTimeFilters(props.timeFilters) ===
    AGGREGATION_TYPE.DATE
  ) {
    emit('change', AGGREGATION_TYPE.DATE)
  }
})

const aggregationTypeOptions = computed(() => [
  {
    text: AGGREGATION_TYPE_TITLES.DATE,
    value: AGGREGATION_TYPE.DATE,
    disabled: false,
  },

  {
    text: AGGREGATION_TYPE_TITLES.WEEK,
    value: AGGREGATION_TYPE.WEEK,
    disabled:
      calcAggregationAccordingToTimeFilters(props.timeFilters) ===
      AGGREGATION_TYPE.DATE,
  },
  {
    text: AGGREGATION_TYPE_TITLES.MONTH,
    value: AGGREGATION_TYPE.MONTH,
    disabled:
      calcAggregationAccordingToTimeFilters(props.timeFilters) ===
        AGGREGATION_TYPE.DATE ||
      calcAggregationAccordingToTimeFilters(props.timeFilters) ===
        AGGREGATION_TYPE.WEEK,
  },
])

const onChangeAggregation = (value: string) => {
  emit('change', value)
  hideOverlayPanel()
}

const hideOverlayPanel = () => {
  panel.value.hide()
}
</script>
