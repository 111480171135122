<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="onCloseDialog"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        {{ drilldownData?.name }}
        <TableSearchInput
          :value="search"
          @update:value="search = $event || null"
          placeholder="Search by ticket..."
        ></TableSearchInput>
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">Reopened tickets</div>
    <div class="height flex mb-8" v-if="loading">
      <LoaderWrapper></LoaderWrapper>
    </div>
    <MyTable
      v-else
      :table-data="ticketsData"
      :columns-data="columnsData"
      customHeight="70vh"
      :scrollable="scrollableTable"
      class="mb-8"
      lazy-table
      :lazy-loading="lazyLoading"
      @row-expand="getTicketDetails($event.data.issue_key)"
      expander
      @load="onLazyLoad"
      @sort="onSort"
    >
      <template #issue_key="{ data }">
        <Link :href="data.absolute_url" style-class="truncate">
          {{ `${data.issue_key}: ${data.issue_name}` }}
        </Link>
      </template>
      <template #expansion="{ data }">
        <div class="p-3 w-full">
          <MyTable
            :table-data="ticketDetails[data.data.issue_key].changes"
            :columns-data="ticketDetailsColumnsData"
            :lazy-loading="ticketDetails[data.data.issue_key].loading"
          >
            <template #from_status="{ data }">
              <TicketStatusChip :status="data.from_status" />
            </template>
            <template #to_status="{ data }">
              <TicketStatusChip :status="data.to_status" />
            </template>
            <template #changelog_created_at="{ data }">
              {{ calcDateFormat(data.changelog_created_at) }}
            </template>
          </MyTable>
        </div>
      </template>
    </MyTable>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog/Dialog.vue'
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import { useStore } from '@/store'
import { calcSortOrderingAndField, showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import { Dictionary, equals, reject } from 'ramda'
import Link from '@/components/common/Link.vue'
import { Filters } from '@/store/modules/filters'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import { debounce } from 'lodash'
import { calcDateFormat } from '@/utils/date-utils'
import TicketStatusChip from '@/components/common/TicketStatusChip.vue'
import { Aggregate_By_Type } from '@/types/types'
import { AGGREGATE_BY } from '@/constants/constants'

const props = defineProps<{
  open: boolean
  drilldownData: { id: number; name: string } | null
  aggregation: Aggregate_By_Type
  filters: Filters
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const ticketsData = ref([])
const loading = ref(false)
const lazyLoading = ref(false)
const store = useStore()
const toast = useToast()
const search = ref(null)
const sortField = ref<string | null>(null)
const scrollableTable = ref(true)
const ticketDetails = ref<any>({})
const nextPage = ref<string | null>(null)

const isAggregationByProjects = computed(
  () => props.aggregation === AGGREGATE_BY.PROJECTS
)
const drilldownDetails = computed(() => props.drilldownData)

const debouncedGetRequest = debounce(getFirstPageOfReopenedTicketsFunc, 1000)

watch([sortField, search, drilldownDetails], () => {
  if (search.value) {
    debouncedGetRequest()
  } else {
    drilldownDetails.value && getFirstPageOfReopenedTicketsFunc()
  }
})

const activeFilters = computed(() =>
  reject(equals(null))(props.filters as Dictionary<null>)
)

const columnsData = [
  {
    header: 'Ticket',
    field: 'issue_key',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:3; padding-right: 10px',
  },
  {
    header: 'Count',
    field: 'count',
    is_sortable: true,
    use_template: false,
    styles: 'flex-grow:1; padding-right: 10px',
  },
]

/* istanbul ignore next */
async function onLazyLoad(event: { first: number; last: number }) {
  const { last } = event
  if (!last || lazyLoading.value) return
  try {
    if (nextPage.value && last === ticketsData.value.length) {
      lazyLoading.value = true
      const page = await store.dispatch(
        'reopenedTickets/getNextPageOfReopenedTickets',
        nextPage.value
      )
      nextPage.value = page.next
      ticketsData.value = ticketsData.value.concat(page.results)
    }
    lazyLoading.value = false
  } catch (e) {
    lazyLoading.value = false
    showToastError(toast, e)
  }
}

async function getFirstPageOfReopenedTicketsFunc() {
  const params = {
    ...activeFilters.value,
    ...(isAggregationByProjects.value
      ? { projects: `${props.drilldownData?.id}` }
      : { users: `${props.drilldownData?.id}` }),

    ordering: sortField.value,
    search: search.value,
  }
  const activeParams = {
    ...reject(equals(null))(params as Dictionary<any>),
  }
  if (activeParams.search || activeParams.ordering) {
    lazyLoading.value = true
  } else {
    loading.value = true
  }
  try {
    const page = await store.dispatch(
      'reopenedTickets/getFirstPageOfReopenedTickets',
      activeParams
    )
    nextPage.value = page?.next
    ticketsData.value = page?.results
  } catch (e) {
    showToastError(toast, e)
  } finally {
    loading.value = false
    lazyLoading.value = false
  }
}

async function getTicketDetails(issue_key: string) {
  if (ticketDetails.value[issue_key]) return
  ticketDetails.value[issue_key] = { loading: true }
  try {
    ticketDetails.value[issue_key].changes = await store.dispatch(
      'reopenedTickets/getTicketDetails',
      {
        ...activeFilters.value,
        ...(isAggregationByProjects.value
          ? { projects: `${props.drilldownData?.id}` }
          : { users: `${props.drilldownData?.id}` }),
        issue_key: issue_key,
      }
    )
    ticketDetails.value[issue_key].loading = false
  } catch (e) {
    showToastError(toast, e)
    ticketDetails.value[issue_key].loading = false
  }
}

const ticketDetailsColumnsData = [
  {
    header: 'From status',
    field: 'from_status',
    is_sortable: true,
    use_template: true,
  },
  {
    header: 'To status',
    field: 'to_status',
    is_sortable: true,
    use_template: true,
  },
  {
    header: 'Date',
    field: 'changelog_created_at',
    is_sortable: true,
    use_template: true,
  },
]

function onSort(event: Event) {
  sortField.value = calcSortOrderingAndField(event)
}

const onCloseDialog = () => {
  emit('close')
  search.value = null
  sortField.value = null
}
</script>

<style scoped>
.header-wrapper {
  @apply flex items-center justify-between w-full text-base;
}
.height {
  height: 75vh;
}
</style>
