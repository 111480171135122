<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.ProjectAllocation]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.ProjectAllocation]"
    :is-chart-data-empty="isEmpty(chartData)"
    :type="chartTypes.ProjectAllocation"
    :icons="['tempo']"
  >
    <BasicChart
      :data="projectAllocationData"
      :options="projectAllocationOptions"
      type="pie"
      :chart-name="chartTypes.ProjectAllocation"
    ></BasicChart>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps } from 'vue'
import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty } from 'ramda'
import {
  hideCursorOnLegendLeave,
  randomizedColorsArray,
  showCursorOnLegendHover,
} from '@/utils/chart-utils'
import { ProjectAllocation } from '@/store/modules/charts/project-allocation'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import { ChartEvent, TooltipItem } from 'chart.js'

const props = defineProps<{ filters: Filters; projectPage?: boolean }>()

const {
  response: chartData,
  loading,
  fetchError,
} = useGettingChartData(props, chartTypes.ProjectAllocation)

const projectAllocationData = computed(() => ({
  labels: chartData.value.map((data: ProjectAllocation) => data.project?.name),
  datasets: [
    {
      cutout: '80%',
      datalabels: { display: false },
      label: 'Allocation',
      data: chartData.value,
      backgroundColor: randomizedColorsArray(),
      hoverOffset: 4,
      borderRadius: 4,
      hoverBorderColor: 'white',
    },
  ],
}))

const projectAllocationOptions = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  parsing: {
    key: 'allocation_percent',
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: TooltipItem<'pie'>): string => {
          const { label, dataIndex } = context
          const dataItem = context.dataset.data[dataIndex]
          return `${label}: ${Math.round(
            dataItem?.allocation_percent || 0
          )}% / ${Math.round(
            dataItem.project_time_spent_seconds / 3600
          )}h of ${Math.round(dataItem.overall_time_spent_seconds / 3600)}h`
        },
      },
    },
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      position: 'right',
      align: 'center',
      labels: {
        borderRadius: 4,
        boxWidth: 12,
        boxHeight: 12,
        generateLabels(chart) {
          const dataset = chart.data.datasets[0]
          return chart.data.labels.map((label, i) => ({
            index: i,
            text: `${label}: ${Math.round(
              dataset.data[i]?.allocation_percent || 0
            )}% / ${Math.round(
              dataset.data[i]?.project_time_spent_seconds / 3600
            )}h`,
            fillStyle: dataset.backgroundColor[i],
            strokeStyle: dataset.backgroundColor[i],
            hidden: !chart.getDataVisibility(i),
          }))
        },
      },
    },
  },
}))
</script>
