<template>
  <Dialog
    :visible="open"
    :style="{ width: '90vw' }"
    :dismissableMask="true"
    @update:visible="close"
    position="bottom"
    modal
  >
    <template #header>
      <div class="header-wrapper">
        {{ calendarWeekData?.project_name || 'Project name' }} • Calendar week
      </div>
    </template>
    <div class="text-xl font-semi-bold mb-4">{{ title }}</div>
    <div class="flex gap-16 mb-8">
      <div class="loader-height w-full flex" v-if="loading">
        <LoaderWrapper></LoaderWrapper>
      </div>
      <div v-if="!loading" class="w-2/3">
        <MilestonesTable
          :filters="{
            ...calendarWeekData?.filters,
          }"
          project-page
          popup
          :key="originalReport"
          :selected-week-date="calendarWeekData?.date"
          @close-calendar-week-popup="openFollowUpPopup"
        >
        </MilestonesTable>
        <Divider class="my-8" />
        <BudgetPlannedVsUsedChart
          :filters="chartsFilters"
          project-page
          milestone-popup
        >
        </BudgetPlannedVsUsedChart>
        <Divider class="my-8" />
        <ScopeVsDeliveredChart
          :filters="chartsFilters"
          project-page
          milestone-popup
        ></ScopeVsDeliveredChart>
      </div>
      <div v-if="!loading" class="w-1/3">
        <div class="flex items-center justify-between mb-4">
          <div class="font-semi-bold">Details</div>
          <div v-if="editMode" class="flex gap-2">
            <Button
              class="p-button-outlined cancel-button"
              size="small"
              @click="cancelEditing"
              >Cancel</Button
            >
            <Button class="save-button" size="small" @click="saveReport"
              >Save</Button
            >
          </div>
          <IconButton
            v-else
            icon="pi-pencil"
            icon-position="left"
            icon-size="12px"
            class="p-button-outlined edit-button"
            size="small"
            @click="editMode = true"
            >Edit</IconButton
          >
        </div>
        <PopupBlock title="RAG Status" class="mb-1">
          <BaseDropdown
            v-if="editMode"
            v-model:selected="formData.status"
            :options="statusesOptions"
          >
            <template #value="slotProps">
              <ColorOption :value="slotProps.slotProps.value"></ColorOption>
            </template>
            <template #option="slotProps">
              <ColorOption :value="slotProps.slotProps.option.id"></ColorOption>
            </template>
          </BaseDropdown>
          <ColorOption v-else :value="formData.status"></ColorOption>
        </PopupBlock>
        <PopupBlock title="Description" class="mb-1">
          <BaseTextarea
            v-if="editMode"
            v-model:value="formData.description"
          ></BaseTextarea>
          <div v-else>{{ formData.description || 'Not added' }}</div>
        </PopupBlock>
        <PopupBlock title="Budget" class="mb-1">
          <BaseTextarea
            v-if="editMode"
            v-model:value="formData.budget"
          ></BaseTextarea>
          <div v-else>{{ formData.budget || 'Not added' }}</div>
        </PopupBlock>
        <PopupBlock title="Phase" class="mb-1">
          <BaseDropdown
            v-if="editMode"
            v-model:selected="formData.phase"
            :options="phaseOptions"
          ></BaseDropdown>
          <div v-else>
            {{
              phaseOptions.find((opt) => opt.id === formData.phase)?.name || ''
            }}
          </div>
        </PopupBlock>
        <PopupBlock title="Project Manager" class="mb-1">
          <div>
            {{
              projectManagers.find((opt) => opt.id === formData.lead_id)
                ?.name || ''
            }}
          </div>
        </PopupBlock>
      </div>
      <CancelEditConfirmationDialog
        v-model:open="showCancelEditDialog"
        @continue="closePopup"
      ></CancelEditConfirmationDialog>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import Dialog from 'primevue/dialog/Dialog.vue'
import {
  format,
  getISOWeek,
  startOfISOWeek,
  lastDayOfISOWeek,
  endOfISOWeek,
} from 'date-fns'
import MilestonesTable, {
  CalendarWeekPopupData,
  MilestonePopupData,
  PopupType,
} from '@/components/charts/milestones/MilestonesTable.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import { Filters } from '@/store/modules/filters'
import ScopeVsDeliveredChart from '@/components/charts/scope-vs-delivered/ScopeVsDeliveredChart.vue'
import BudgetPlannedVsUsedChart from '@/components/charts/budget-planned-vs-used/BudgetPlannedVsUsedChart.vue'
import PopupBlock from '@/components/charts/milestones/PopupBlock.vue'
import BaseTextarea from '@/components/common/base/BaseTextarea.vue'
import BaseDropdown from '@/components/common/base/BaseDropdown.vue'
import ColorOption from '@/components/charts/milestones/ColorOption.vue'
import { useStore } from '@/store'
import {
  FILTERS_DATE_FORMAT,
  MILESTONE_WEEK_PHASE,
  MILESTONE_WEEK_STATUS,
  USER_POSITIONS,
} from '@/constants/constants'
import { WeeklyReport } from '@/store/modules/charts/milestones'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import CancelEditConfirmationDialog from '@/components/common/dialogs/CancelEditConfirmationDialog.vue'

const props = defineProps<{
  open: boolean
  calendarWeekData: {
    date: string
    project_id: number
    project_name: string
    filters: Filters
  } | null
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'reload-milestone'): void
  (e: 'toggle-popups', value: MilestonePopupData): void
}>()

const initialForm = () => ({
  status: MILESTONE_WEEK_STATUS.GREEN,
  description: '',
  budget: '',
  phase: MILESTONE_WEEK_PHASE.DEVELOPMENT,
  lead_id: undefined,
})

const store = useStore()
const toast = useToast()
const loading = ref(false)
const editMode = ref(false)
const showCancelEditDialog = ref(false)
const followUpPopupData = ref<{
  popup: PopupType
  data: MilestonePopupData | CalendarWeekPopupData
} | null>(null)
const isStatusUpdated = ref(false)

const formData = ref<Partial<WeeklyReport> | null>(initialForm())
const originalReport = ref<WeeklyReport | null>(null)

const chartsFilters = computed(() => {
  return {
    ...props.calendarWeekData?.filters,
    until: props.calendarWeekData?.date
      ? format(
          endOfISOWeek(new Date(props.calendarWeekData?.date)),
          FILTERS_DATE_FORMAT
        )
      : null,
    since: null,
    scale_type: 'week',
  }
})

watch(
  () => props.calendarWeekData,
  async () => {
    if (props.calendarWeekData) {
      try {
        loading.value = true
        const report = await store.dispatch(
          'milestones/getCalendarWeekReport',
          {
            project: String(props.calendarWeekData?.project_id),
            date: props.calendarWeekData?.date,
          }
        )
        if (report) {
          originalReport.value = report
          formData.value = { ...originalReport.value }
          loading.value = false
        }
      } catch (e) {
        showToastError(toast, e)
        loading.value = false
      }
    } else {
      originalReport.value = null
      formData.value = initialForm()
    }
  }
)

const title = computed(() => {
  const date = props.calendarWeekData?.date
  if (!date) return ''
  const week = getISOWeek(new Date(date))
  const firstDayOfWeek = startOfISOWeek(new Date(date))
  const lastDayOfWeek = lastDayOfISOWeek(new Date(date))

  const monthOfFirstDayOfWeek = format(firstDayOfWeek, 'MMMM')
  const monthOfLastDayOfWeek = format(lastDayOfWeek, 'MMMM')
  return `CW${week}, ${monthOfFirstDayOfWeek} ${format(
    firstDayOfWeek,
    'd'
  )} - ${
    monthOfLastDayOfWeek === monthOfFirstDayOfWeek ? '' : monthOfLastDayOfWeek
  } ${format(lastDayOfWeek, 'd')}`
})

const statusesOptions = [
  { name: 'Green', id: MILESTONE_WEEK_STATUS.GREEN },
  { name: 'Yellow', id: MILESTONE_WEEK_STATUS.YELLOW },
  { name: 'Red', id: MILESTONE_WEEK_STATUS.RED },
]

const phaseOptions = [
  { name: 'Development', id: MILESTONE_WEEK_PHASE.DEVELOPMENT },
  { name: 'Maintenance', id: MILESTONE_WEEK_PHASE.MAINTENANCE },
  { name: 'On hold', id: MILESTONE_WEEK_PHASE.ON_HOLD },
]

const projectManagers = computed(() => {
  return store.state.admin.original_users
    .filter((user) => user.position === USER_POSITIONS.MANAGER)
    .map((user) => ({ name: user.name, id: user.id }))
})

const saveReport = async () => {
  try {
    const report = await store.dispatch('milestones/updateCalendarWeekData', {
      ...formData.value,
    })
    if (originalReport.value?.status !== formData.value?.status) {
      isStatusUpdated.value = true
    }
    originalReport.value = { ...report }
    editMode.value = false
  } catch (e) {
    showToastError(toast, e)
  }
}

const cancelEditing = () => {
  formData.value = { ...originalReport.value }
  editMode.value = false
}

const close = () => {
  if (editMode.value) {
    showCancelEditDialog.value = true
  } else {
    closePopup()
  }
}

const closePopup = () => {
  formData.value = initialForm()
  originalReport.value = null
  editMode.value = false
  showCancelEditDialog.value = false
  if (followUpPopupData.value) {
    emit('toggle-popups', followUpPopupData.value)
  }
  followUpPopupData.value = null
  emit('close')
  if (isStatusUpdated.value) {
    emit('reload-milestone')
  }
}

const openFollowUpPopup = (value: {
  popup: PopupType
  data: MilestonePopupData | CalendarWeekPopupData
}) => {
  followUpPopupData.value = value
  if (editMode.value) {
    showCancelEditDialog.value = true
  } else {
    closePopup()
  }
}
</script>

<style scoped lang="scss">
.loader-height {
  height: 70vh;
}
</style>
