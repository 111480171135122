<template>
  <StickyTopbar title="Project Portfolio">
    <template #buttons>
      <TimeFilter
        :filters="timeFilters"
        @change-time="onDateChanged"
      ></TimeFilter>
      <MultipleFilteringMenu
        name="Projects"
        :filters="filters.projects"
        :filtering-options="filteringOptions.projects"
        @change-filtering="onFilterChanged($event, 'projects')"
      ></MultipleFilteringMenu>
      <MultipleFilteringMenu
        v-if="showMembersFilter"
        name="Members"
        :filters="filters.users"
        :filtering-options="filteringOptions.users"
        @change-filtering="onFilterChanged($event, 'users')"
      ></MultipleFilteringMenu>
    </template>
  </StickyTopbar>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { useStore } from '@/store'
import { Dictionary, equals, omit, pick } from 'ramda'
import TimeFilter from '@/components/common/filters/global/time-filter/TimeFilter.vue'
import MultipleFilteringMenu from '@/components/common/filters/global/MultipleFilteringMenu.vue'
import { Filters, TimeFilters } from '@/store/modules/filters'
import { useRouter } from 'vue-router'
import { USER_ROLES } from '@/constants/constants'
import StickyTopbar from '@/components/common/StickyTopbar.vue'

const props = defineProps<{ filteringOptions: any; filters: Filters }>()
const timeFilters = computed(() => pick(['since', 'until'], props.filters))
const store = useStore()
const router = useRouter()

const showMembersFilter = computed(() =>
  [USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.TEAM_LEAD].includes(
    store.getters['company/userRole']
  )
)

const onFilterChanged = (ev: number[], prop: string) => {
  if (
    ev.length === props.filteringOptions[prop].length &&
    !equals(null, (props.filters as Dictionary<any>)[prop])
  ) {
    router.push({
      path: `/company/${store.getters['company/selectedCompanyId']}/portfolio`,
      query: { ...omit([prop], props.filters) },
    })
  } else if (
    !!ev.length &&
    !equals(ev, (props.filters as Dictionary<any>)[prop]) &&
    ev.length !== props.filteringOptions[prop].length
  ) {
    router.push({
      path: `/company/${store.getters['company/selectedCompanyId']}/portfolio`,
      query: { ...props.filters, ...{ [prop]: ev } },
    })
  }
}

const onDateChanged = (dates: TimeFilters) => {
  if (
    !equals(dates.since, props.filters.since) ||
    !equals(dates.until, props.filters.until)
  ) {
    router.push({
      path: `/company/${store.getters['company/selectedCompanyId']}/portfolio`,
      query: { ...props.filters, ...dates },
    })
  }
}
</script>
