<template>
  <TableWrapper :title="tableTitle">
    <template #button>
      <TableSearchInput
        v-model:value="search"
        placeholder="Search by project"
        class="mr-2"
      ></TableSearchInput>
      <IconButton
        class="p-button-outlined add-project-mapping-button"
        size="small"
        icon="pi-plus"
        icon-position="left"
        icon-size="12px"
        @click="showCreateDialog = true"
        >Add</IconButton
      >
    </template>
    <template #default>
      <LoaderWrapper v-if="loading"></LoaderWrapper>
      <MyTable
        v-else
        :table-data="projects"
        :columns-data="columnsData"
        :filters="filters"
        :filter-fields="filterFields"
        scrollable
        class="project-mapping-table"
      >
        <template #is_active="{ data }">
          <StatusWidget :status="data.is_active"></StatusWidget>
        </template>
        <template #editable="{ data }">
          <IconButton
            size="small"
            class="p-button-text invisible group-hover:visible"
            @click="selectedProjectId = data.id"
            ><i
              class="pi pi-pencil"
              style="font-size: 12px"
              aria-hidden="true"
            ></i
          ></IconButton>
        </template>
      </MyTable>
      <ProjectSetupFlowDialog
        v-model:open="showCreateDialog"
      ></ProjectSetupFlowDialog>
      <EditProjectDialog v-model:id="selectedProjectId"></EditProjectDialog>
    </template>
  </TableWrapper>
</template>

<script setup lang="ts">
import TableWrapper from '@/components/common/table/TableWrapper.vue'
import IconButton from '@/components/common/buttons/IconButton.vue'
import { State, useStore } from '@/store'
import { computed, onMounted, ref } from 'vue'
import StatusWidget from '@/components/common/StatusWidget.vue'
import MyTable from '@/components/common/table/MyTable.vue'
import ProjectSetupFlowDialog from '@/components/admin-dashboard/system/project-mapping/project-setup-flow/ProjectSetupFlowDialog.vue'
import EditProjectDialog from '@/components/admin-dashboard/system/project-mapping/EditProjectMappingDialog.vue'
import { showToastError } from '@/utils/utils'
import { useToast } from 'primevue/usetoast'
import LoaderWrapper from '@/components/common/loader/LoaderWrapper.vue'
import { Store } from 'vuex'
import TableSearchInput from '@/components/common/table/TableSearchInput.vue'
import useTableSearch from '@/utils/hooks/useTableSearch'

const store: Store<State> = useStore()
const showCreateDialog = ref(false)
const selectedProjectId = ref(false)
const toast = useToast()
const loading = ref(false)
const filterFields = ['name']
const { search, filters } = useTableSearch()

onMounted(() => {
  loading.value = true
  store
    .dispatch('admin/getProjects')
    .catch((e) => {
      showToastError(toast, e)
    })
    .finally(() => (loading.value = false))
})

const projects = computed(() => store.state.admin.original_projects)
const tableTitle = computed(() => `Project mapping • ${projects.value.length}`)
const columnsData = [
  {
    header: 'Projects',
    field: 'name',
    is_sortable: true,
    use_template: false,
    styles: 'flex-grow:2; padding-right: 10px',
  },
  {
    header: 'Status',
    field: 'is_active',
    is_sortable: true,
    use_template: true,
    styles: 'flex-grow:1; padding-right: 10px',
  },
  {
    header: '',
    field: 'editable',
    small: true,
    is_sortable: false,
    use_template: true,
    styles: 'flex-grow:0; flex-basis:30px',
  },
]
</script>
