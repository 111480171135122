<template>
  <div
    class="header-cell whitespace-pre text-center"
    :class="headerClasses"
    @click="projectPage && $emit('show-calendar-popup', date)"
  >
    <span :class="selectedDateClasses">
      {{
        `${format(new Date(date), 'MMM')}${
          isThisYear(new Date(date)) ? '' : `'${format(new Date(date), 'yy')}`
        }\nCW${getISOWeek(new Date(date))}`
      }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { format, getISOWeek, isThisWeek, isThisYear } from 'date-fns'
import {
  milestoneCellBorder,
  milestonePlanningBackground,
} from '@/utils/chart-utils'

const props = defineProps<{
  projectPage?: boolean
  popup?: boolean
  tableData: any
  selectedWeekDate?: string
  date: string
}>()

const dateData = computed(() => {
  return (props.projectPage && props.tableData[0]?.[props.date]) || {}
})

const selectedDateClasses = computed(() => {
  if (
    (props.popup && props.selectedWeekDate === props.date) ||
    (!props.popup && isThisWeek(new Date(props.date)))
  ) {
    return 'current-week bg-black text-white px-1 rounded-md'
  }
  return ''
})

const headerClasses = computed(() =>
  props.projectPage
    ? [
        milestonePlanningBackground(dateData.value?.plans),
        milestoneCellBorder(dateData.value?.weekly_reports?.status),
      ].join(' ')
    : ''
)
</script>
