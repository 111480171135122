<template>
  <LoginLayout>
    <div class="relative w-full h-full" v-if="isInitialState && isInitialFlow">
      <div class="square-content">
        <span class="welcome">
          Welcome <br />
          to Devflow!
        </span>
        <div class="flex flex-col gap-5">
          <Button
            size="large"
            class="p-button-danger justify-center google-button"
            @click="loginWithGoogle"
          >
            <img src="../assets/icons/google.svg" alt="Google" class="mr-3" />
            <span>Continue with Google</span>
          </Button>
          <Button
            size="large"
            class="justify-center email-button"
            @click="setEmailState"
          >
            <img src="../assets/icons/email.svg" alt="Email" class="mr-3" />
            <span>Continue with Email</span>
          </Button>
        </div>
      </div>
    </div>

    <div
      class="w-full h-full relative"
      v-else-if="isEmailState || isGoogleState"
    >
      <IconButton
        icon="pi-arrow-left"
        class="back-button p-button-text"
        icon-position="left"
        icon-size="18"
        @click="goToInitialState"
        >Back</IconButton
      >
      <div class="square-content">
        <div class="flex flex-col h-full w-full">
          <span class="welcome">
            What is your <br />
            email address?
          </span>
          <BaseInputText
            v-model:value="formData.email"
            class="p-inputtext-lg"
            icon-left="pi-envelope"
            type="email"
            placeholder="michael.scott@gmail.com"
            :errors="v$.email.$errors"
            autofocus
            @clear="onEmailCleared"
          ></BaseInputText>
          <div
            v-if="isGoogleState"
            class="text-gray-700 mt-2 text-base text-left"
          >
            This email address is associated with an already registered Google
            account.
          </div>
          <div
            v-else-if="isCreateFlow"
            class="text-gray-700 mt-2 text-base text-left"
          >
            This email isn’t associated with a Devflow account.
          </div>
          <div class="w-full mt-10 flex justify-end">
            <Button size="large" v-if="isGoogleState" @click="loginWithGoogle">
              <img
                src="../assets/icons/google.svg"
                alt="Google icon"
                class="pr-3"
              />
              <span> Continue with Google </span>
            </Button>
            <IconButton
              v-else
              size="large"
              icon-position="right"
              icon="pi-arrow-right"
              class="next-button"
              @click="proceedToPassword"
              >{{ isCreateFlow ? 'Create an account' : 'Next' }}</IconButton
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full h-full relative"
      v-else-if="isPasswordState && isLoginFlow"
    >
      <IconButton
        icon="pi-arrow-left"
        class="back-button p-button-text"
        icon-position="left"
        icon-size="18"
        @click="goToEmailState"
        >Back</IconButton
      >
      <div class="square-content">
        <form ref="passwordForm" class="flex flex-col h-full w-full">
          <span class="welcome">
            Enter your <br />
            password.
          </span>
          <BaseInputText
            v-model:value="formData.loginPassword"
            class="p-inputtext-lg"
            icon-left="pi-lock"
            type="password"
            placeholder="Enter password..."
            :errors="v$.loginPassword.$errors"
            autofocus
          ></BaseInputText>
          <div class="w-full mt-10 flex justify-between items-center">
            <a href="#" class="text-gray-800 text-lg"> Forgot password? </a>
            <IconButton
              size="large"
              icon-position="right"
              icon="pi-arrow-right"
              @click="loginWithEmail"
              >Next</IconButton
            >
          </div>
        </form>
      </div>
    </div>
    <div
      class="w-full h-full relative"
      v-else-if="isPasswordState && isCreateFlow"
    >
      <IconButton
        icon="pi-arrow-left"
        class="back-button p-button-text"
        icon-position="left"
        icon-size="18"
        @click="goToEmailState"
        >Back</IconButton
      >
      <div class="square-content">
        <form ref="passwordForm" autofocus class="flex flex-col h-full w-full">
          <span class="welcome">
            Create your <br />
            password.
          </span>
          <BaseInputText
            v-model:value="formData.creationPassword"
            class="p-inputtext-lg"
            icon-left="pi-lock"
            type="password"
            placeholder="Enter password..."
            autofocus
            style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
          ></BaseInputText>
          <BaseInputText
            v-model:value="formData.repeatPassword"
            class="p-inputtext-lg"
            icon-left="pi-lock"
            type="password"
            placeholder="Confirm password..."
            style="border-top-left-radius: 0; border-top-right-radius: 0"
          ></BaseInputText>
          <div
            class="mt-2 text-base text-left"
            :class="
              !v$.creationPassword.$invalid ? 'text-black' : 'text-gray-700'
            "
          >
            Password must be at least 8 characters
            <i
              v-if="!v$.creationPassword.$invalid"
              class="pi pi-check-circle"
              aria-hidden="true"
            ></i>
          </div>
          <div
            class="mt-1 text-base text-left"
            :class="
              !v$.repeatPassword.$invalid && formData.repeatPassword
                ? 'text-black'
                : 'text-gray-700'
            "
          >
            Passwords must match
            <i
              v-if="!v$.repeatPassword.$invalid && formData.repeatPassword"
              class="pi pi-check-circle"
              aria-hidden="true"
            ></i>
          </div>
          <div class="w-full mt-10 flex justify-end">
            <IconButton
              size="large"
              icon-position="right"
              icon="pi-arrow-right"
              @click="createNewUser"
              :disabled="
                v$.creationPassword.$invalid || v$.repeatPassword.$invalid
              "
              >Next</IconButton
            >
          </div>
        </form>
      </div>
    </div>
  </LoginLayout>
</template>

<script setup lang="ts">
import router from '@/router'
import { authService } from '@/services/auth'
import IconButton from '@/components/common/buttons/IconButton.vue'
import Button from '@/components/common/buttons/Button.vue'
import { required, email, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { ref, computed, reactive, getCurrentInstance } from 'vue'
import { useToast } from 'primevue/usetoast'
import { TOAST_LIFE_TIME } from '@/constants/constants'
import LoginLayout from '@/layouts/LoginLayout.vue'
import BaseInputText from '@/components/common/base/BaseInputText.vue'

enum STATE {
  INITIAL = 'initial',
  EMAIL = 'email',
  PASSWORD = 'password',
  GOOGLE = 'google.com',
}

enum FLOW {
  INITIAL = 'initial',
  CREATE = 'create',
  LOGIN = 'login',
}
const state = ref<STATE>(STATE.INITIAL)
const flow = ref<FLOW>(FLOW.INITIAL)
const toast = useToast()
const formData = reactive({
  email: '',
  loginPassword: '',
  creationPassword: '',
  repeatPassword: '',
})

const rules = {
  email: { required, email, $lazy: true },
  loginPassword: { required, minLength: minLength(8), $lazy: true },
  creationPassword: { required, minLength: minLength(8) },
  repeatPassword: {
    sameAsPassword: function (pwd: string) {
      return formData.creationPassword === pwd
    },
  },
}

const v$ = useVuelidate(rules, formData)

const isInitialState = computed(() => {
  return state.value === STATE.INITIAL
})
const isEmailState = computed(() => {
  return state.value === STATE.EMAIL
})
const isPasswordState = computed(() => {
  return state.value === STATE.PASSWORD
})
const isInitialFlow = () => {
  return flow.value === FLOW.INITIAL
}
const isCreateFlow = computed(() => {
  return flow.value === FLOW.CREATE
})
const isLoginFlow = computed(() => {
  return flow.value === FLOW.LOGIN
})
const isGoogleState = computed(() => {
  return state.value === STATE.GOOGLE
})

const setEmailState = () => {
  state.value = STATE.EMAIL
}
const setPasswordState = () => {
  state.value = STATE.PASSWORD
}
const setCreateFlow = () => {
  flow.value = FLOW.CREATE
}
const setLoginFlow = () => {
  flow.value = FLOW.LOGIN
}
const setInitialFlow = () => {
  flow.value = FLOW.INITIAL
}
const setInitialState = () => {
  state.value = STATE.INITIAL
}
const setGoogleState = () => {
  state.value = STATE.GOOGLE
}

const goToEmailState = () => {
  setEmailState()
  setInitialFlow()
}
const goToInitialState = () => {
  setInitialState()
  setInitialFlow()
}
const proceedToPassword = () => {
  v$.value.email.$touch()
  if (v$.value.email.$invalid) {
    return
  }
  if (isEmailState.value && isCreateFlow.value) {
    setPasswordState()
  } else {
    checkUserEmail()
  }
}

const onEmailCleared = () => {
  setEmailState()
  setLoginFlow()
}

async function checkUserEmail(): Promise<void> {
  try {
    const methods = await authService.getLoginMethods(formData.email)
    switch (true) {
      case !methods.length:
        setCreateFlow()
        break
      case methods.includes(STATE.PASSWORD):
        setLoginFlow()
        setPasswordState()
        break
      case methods.includes(STATE.GOOGLE):
        setLoginFlow()
        setGoogleState()
        break
      default:
        toast.add({
          severity: 'error',
          detail: 'Already have another login method',
          life: TOAST_LIFE_TIME,
        })
        break
    }
  } catch ({ message }) {
    toast.add({
      severity: 'error',
      detail: message,
      life: TOAST_LIFE_TIME,
    })
  }
}

async function loginWithEmail(): Promise<void> {
  v$.value.loginPassword.$touch()
  if (v$.value.loginPassword.$invalid) {
    return
  }
  try {
    const data: any = await authService.loginWithEmail(
      formData.email,
      formData.loginPassword
    )
    if (!data.user) {
      data.code === 'auth/wrong-password'
        ? toast.add({
            severity: 'error',
            detail: 'Incorrect password entered, please try again.',
            life: TOAST_LIFE_TIME,
          })
        : toast.add({
            severity: 'error',
            detail: data.message,
            life: TOAST_LIFE_TIME,
          })
    } else {
      router.push('/companies')
    }
  } catch ({ code, message, name, stack }) {
    toast.add({
      severity: 'error',
      detail: message,
      life: TOAST_LIFE_TIME,
    })
  }
}

async function createNewUser(): Promise<void> {
  await authService.createUser(formData.email, formData.creationPassword)
  router.push('/companies')
}
async function loginWithGoogle(): Promise<void> {
  try {
    await authService.loginWithGoogle()
    router.push('/companies')
  } catch ({ message }) {
    toast.add({
      severity: 'error',
      detail: message,
      life: TOAST_LIFE_TIME,
    })
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.LoginPage = getCurrentInstance()
}
</script>

<style lang="scss">
.back-button {
  @apply absolute top-3 left-3;
}

.square-content {
  padding: 18.75% 18.75% 0;
  @apply flex flex-col w-full h-full;

  .welcome {
    @apply w-full font-bold text-2xl text-left flex-grow-0 mb-10;
  }
  .link-button {
    @apply flex justify-center align-middle space-x-4 w-full p-3 rounded-lg text-white font-semi-bold;
  }

  .input-icon {
    font-size: 1.25rem;
    color: black;
    margin-left: 4px;
  }

  .clear-icon {
    cursor: pointer;
    margin-right: 4px;
  }
}
</style>
