import axios from '@/services/axios'
import { omit, path } from 'ramda'
import { ActionContext, MutationTree, ActionTree } from 'vuex'
import { State as GlobalState } from '../../index'

export interface Project {
  name?: string
  id: number
  is_active?: boolean
  company?: number
  is_favorite: boolean
  image?: string | null
}

export interface User {
  company?: number
  firstname?: string
  name?: string
  id?: number
  is_active?: boolean
  lastname?: string
  position?: string
  role?: string
  projects?: number[]
}

export interface State {
  original_projects: Project[]
  original_users: User[]
  unmappedUsersSearch: null | string
}

const state = (): State => ({
  original_projects: [],
  original_users: [],
  unmappedUsersSearch: null,
})

const mutations: MutationTree<State> = {
  setProjects(state: State, projects: Project[]): void {
    state.original_projects = projects
  },
  setUsers(state: State, users: User[]): void {
    state.original_users = users
  },
  setUnmappedUsersSearch(state: State, search: string | null): void {
    state.unmappedUsersSearch = search
  },
}

const getters = {
  favouriteProjects(state: State): Project[] {
    return state.original_projects.filter(
      (project: Project) => project.is_favorite
    )
  },
}

type AdminContext = ActionContext<State, GlobalState>

const actions: ActionTree<State, GlobalState> = {
  getProjects({ commit, rootGetters }: AdminContext): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/`
      )
      .then(({ data }) => commit('setProjects', data))
  },
  getActiveProjects({ commit, rootGetters }: AdminContext): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/?is_active=true`
      )
      .then(({ data }) => commit('setProjects', data))
  },
  getUsers({ commit, rootGetters }: AdminContext): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/user-mapping/`
      )
      .then((data) => commit('setUsers', data.data))
  },
  addProject(
    { dispatch, rootGetters }: AdminContext,
    project: Project
  ): Promise<Project> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/`,
        {
          ...project,
        }
      )
      .then(({ data }) => {
        dispatch('getProjects')
        return data
      })
  },
  getProject({ rootGetters }: AdminContext, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${id}/`
      )
      .then(path(['data']))
  },
  getRelatedProjects(
    { rootGetters }: AdminContext,
    originalProjectId: number
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/jira/projects/?original_project=${originalProjectId}`
      )
      .then(({ data }) => data)
  },
  editProject(
    { dispatch, rootGetters }: AdminContext,
    project: Project
  ): Promise<void> {
    return axios
      .put(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${project.id}/`,
        omit(['id'], project)
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  deleteProject(
    { dispatch, rootGetters }: AdminContext,
    id: number
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${id}/`
      )
      .then(path(['data']))
      .then(() => dispatch('getProjects'))
  },
  toggleProjectAsFavourite(
    { dispatch, rootGetters }: AdminContext,
    id: number
  ): Promise<void> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${id}/toggle_favorite/`
      )
      .then(({ data }) => {
        dispatch('getActiveProjects')
        return data
      })
  },
  addUser({ dispatch, rootGetters }: AdminContext, user: User): Promise<User> {
    return axios
      .post(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/user-mapping/`,
        {
          ...user,
        }
      )
      .then(({ data }) => {
        dispatch('getUsers')
        return data
      })
  },
  getUser({ rootGetters }: AdminContext, id: number): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/user-mapping/${id}/`
      )
      .then(path(['data']))
  },
  editUser({ dispatch, rootGetters }: AdminContext, user: User): Promise<void> {
    return axios
      .put(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/user-mapping/${user.id}/`,
        omit(['id'], user)
      )
      .then(path(['data']))
      .then(() => dispatch('getUsers'))
  },
  deleteUser(
    { dispatch, rootGetters }: AdminContext,
    id: number
  ): Promise<void> {
    return axios
      .delete(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/user-mapping/${id}/`
      )
      .then(path(['data']))
      .then(() => dispatch('getUsers'))
  },
  getFirstPageOfUnmappedUsers(
    { rootGetters }: AdminContext,
    params: { ordering: null | string; search: null | string }
  ): Promise<void> {
    const { ordering, search } = params
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/charts/unmapped-users/?limit=50&ordering=${ordering}${
          search ? `&search=${search}` : ''
        }`
      )
      .then(path(['data']))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
