import { isArray } from 'ramda-adjunct'
import { equals } from 'ramda'
import {
  subDays,
  subMonths,
  subYears,
  format,
  startOfWeek,
  endOfWeek,
  secondsToHours,
} from 'date-fns'
import {
  ESTIMATION_TYPE,
  FILTERS_DATE_FORMAT,
  TIME_FILTER_BUTTONS,
} from '@/constants/constants'
import { TimeFilters } from '@/store/modules/filters'
import { ToastServiceMethods } from 'primevue/toastservice'
import { JiraProject } from '@/store/modules/admin/jira'
import { GitlabProject } from '@/store/modules/admin/gitlab'
import { convertSecondsToReadableTime } from '@/utils/date-utils'

export const showErrorMessage = (error: any): void => {
  if (isArray(error?.response?.data)) {
    return error.response.data[0]
  } else {
    return error?.response?.data?.detail || error
  }
}

export const showToastError = (
  toastInstance: ToastServiceMethods,
  error: any
): void => {
  toastInstance.add({
    severity: 'error',
    detail: showErrorMessage(error),
    life: 5000,
  })
}

export const showGitlabProjectsErrorToasts = (
  toastInstance: ToastServiceMethods,
  data: { name: string; message: string }[]
): void => {
  data.forEach((error: { name: string; message: string }) => {
    toastInstance.add({
      severity: 'error',
      detail: showErrorMessage(error.message),
      summary: error.name,
      life: 10000,
    })
  })
}
export const showBitbucketProjectsErrorToasts = (
  toastInstance: ToastServiceMethods,
  data: { name: string; message: string }[]
): void => {
  data.forEach((error: { name: string; message: string }) => {
    toastInstance.add({
      severity: 'error',
      detail: showErrorMessage(error.message),
      summary: error.name,
      life: 10000,
    })
  })
}

export const isAllOriginalNameAdded = (projects: any): boolean => {
  return (
    projects.length &&
    projects.every(
      (project: JiraProject | GitlabProject) =>
        project.original_project !== null
    )
  )
}

export const calcSortOrderingAndField = (event: any): string | null => {
  const { sortOrder, sortField } = event
  const orderingSign = sortOrder === -1 ? '-' : ''
  if (sortField === 'author') {
    return `${orderingSign}author__name`
  } else if (sortField === 'project') {
    return `${orderingSign}project__name`
  } else if (sortOrder && sortField) {
    return `${orderingSign}${sortField}`
  } else {
    return null
  }
}
export const isPreviousWeekSelected = ({
  since,
  until,
}: TimeFilters): boolean =>
  !!since &&
  !!until &&
  equals(
    since,
    format(
      startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
      FILTERS_DATE_FORMAT
    )
  ) &&
  equals(
    until,
    format(
      endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),
      FILTERS_DATE_FORMAT
    )
  )

export const isLastMonthSelected = ({ since, until }: TimeFilters): boolean =>
  !!since &&
  !!until &&
  equals(since, format(subDays(new Date(), 30), FILTERS_DATE_FORMAT)) &&
  equals(until, format(new Date(), FILTERS_DATE_FORMAT))

export const isLast3MonthsSelected = ({ since, until }: TimeFilters): boolean =>
  !!since &&
  !!until &&
  equals(since, format(subMonths(new Date(), 3), FILTERS_DATE_FORMAT)) &&
  equals(until, format(new Date(), FILTERS_DATE_FORMAT))

export const isLast6MonthsSelected = ({ since, until }: TimeFilters): boolean =>
  !!since &&
  !!until &&
  equals(since, format(subMonths(new Date(), 6), FILTERS_DATE_FORMAT)) &&
  equals(until, format(new Date(), FILTERS_DATE_FORMAT))

export const isLastYearSelected = ({ since, until }: TimeFilters): boolean =>
  !!since &&
  !!until &&
  equals(since, format(subYears(new Date(), 1), FILTERS_DATE_FORMAT)) &&
  equals(until, format(new Date(), FILTERS_DATE_FORMAT))

export const calcTimeFilterTitle = (filters: TimeFilters): string | null => {
  if (isPreviousWeekSelected(filters)) {
    return TIME_FILTER_BUTTONS.PREVIOUS_WEEK
  } else if (isLastMonthSelected(filters)) {
    return TIME_FILTER_BUTTONS.LAST_MONTH
  } else if (isLast3MonthsSelected(filters)) {
    return TIME_FILTER_BUTTONS.LAST_3_MONTHS
  } else if (isLast6MonthsSelected(filters)) {
    return TIME_FILTER_BUTTONS.LAST_6_MONTHS
  } else if (isLastYearSelected(filters)) {
    return TIME_FILTER_BUTTONS.LAST_YEAR
  } else {
    return (
      filters.since &&
      filters.until &&
      `${filters.since.replaceAll('-', '/')} - ${filters.until.replaceAll(
        '-',
        '/'
      )}`
    )
  }
}

export const isFilterButtonSelected = (
  title: string,
  filters: TimeFilters
): boolean =>
  (title === TIME_FILTER_BUTTONS.PREVIOUS_WEEK &&
    isPreviousWeekSelected(filters)) ||
  (title === TIME_FILTER_BUTTONS.LAST_MONTH && isLastMonthSelected(filters)) ||
  (title === TIME_FILTER_BUTTONS.LAST_3_MONTHS &&
    isLast3MonthsSelected(filters)) ||
  (title === TIME_FILTER_BUTTONS.LAST_6_MONTHS &&
    isLast6MonthsSelected(filters)) ||
  (title === TIME_FILTER_BUTTONS.LAST_YEAR && isLastYearSelected(filters))

export const isCustomFilterButtonSelected = (
  title: string,
  filters: TimeFilters
): boolean =>
  title === 'Custom Period' &&
  !isPreviousWeekSelected(filters) &&
  !isLastMonthSelected(filters) &&
  !isLast3MonthsSelected(filters) &&
  !isLast6MonthsSelected(filters) &&
  !isLastYearSelected(filters)

export const addZeroToNumberLess10 = (
  num: number | string
): string | number => {
  return num < 10 ? `0${num}` : num
}

export const calcDateCalendarClassForRange = (
  date: {
    day: number
    month: number
    selectable: boolean
    today: boolean
    year: number
  },
  range: (string | null)[]
): string => {
  const { year, day, month, today } = date
  const strDate = `${year}-${addZeroToNumberLess10(
    month + 1
  )}-${addZeroToNumberLess10(day)}`
  if (!range) return ''
  const [since, until] = range
  if (!since) return ''
  const isLastOrFirstDate = until
    ? equals(strDate, since) || equals(strDate, until)
    : equals(strDate, since)
  if (isLastOrFirstDate && today) {
    return 'special-day today-special-day'
  } else if (isLastOrFirstDate) {
    return 'special-day'
  } else {
    return ''
  }
}

export const calcSelectedDateCalendarClass = (
  date: {
    day: number
    month: number
    selectable: boolean
    today: boolean
    year: number
  },
  selectedDate: string
): string => {
  const { year, day, month, today } = date
  const strDate = `${year}-${addZeroToNumberLess10(
    month + 1
  )}-${addZeroToNumberLess10(day)}`
  const isSelectedDate = equals(strDate, selectedDate)
  if (isSelectedDate && today) {
    return 'special-day today-special-day'
  } else if (isSelectedDate) {
    return 'special-day'
  } else {
    return ''
  }
}

export const diffFields =
  (obj: any) =>
  (val: any, key: string): boolean =>
    !equals(val, obj[key])

export const getEstimationString = (
  estimationValue: number,
  estimationType: ESTIMATION_TYPE
): string => {
  if (estimationType === ESTIMATION_TYPE.TIME) {
    return secondsToHours(estimationValue) + 'h'
  } else if (estimationType === ESTIMATION_TYPE.STORY_POINTS) {
    return estimationValue + 'sp'
  }

  return ''
}

export const getEstimationReadableString = (
  estimationValue: number,
  estimationType: ESTIMATION_TYPE
): string => {
  if (estimationType === ESTIMATION_TYPE.TIME) {
    return convertSecondsToReadableTime(estimationValue)
  } else if (estimationType === ESTIMATION_TYPE.STORY_POINTS) {
    return estimationValue + 'sp'
  }

  return ''
}
