<template>
  <Sidebar :show-sidebar="showSidebar" @toggle-sidebar="toggleSidebar">
    <SidebarHeader
      :open="showSidebar"
      :userName="user?.displayName"
      :companyName="company?.name || ''"
      @click="clickOnHeaderEvent = $event"
    ></SidebarHeader>
    <Divider class="sidebar-divider" :class="{ 'mx-3': showSidebar }"></Divider>
    <CompaniesPopup
      :clickEvent="clickOnHeaderEvent"
      :open-sidebar="showSidebar"
      @close="clickOnHeaderEvent = null"
    ></CompaniesPopup>
    <template v-if="showAdminDashboard">
      <SidebarItem
        :page-details="SIDEBAR_PAGES[0]"
        :open="showSidebar"
        :selectedPage="selectedPage"
        :link="getLinkData(SIDEBAR_PAGES[0].value)"
        @click="onClickSidebarItem(SIDEBAR_PAGES[0].value)"
      ></SidebarItem>
      <Divider
        class="sidebar-divider"
        :class="{ 'mx-3': showSidebar }"
      ></Divider>
    </template>
    <div v-if="showSidebar" class="sidebar-title">Views</div>
    <SidebarItem
      v-for="page of takeLast(3, SIDEBAR_PAGES)"
      :key="page.value"
      :selectedPage="selectedPage"
      :page-details="page"
      :open="showSidebar"
      :link="getLinkData(page.value)"
      @click="onClickSidebarItem(page.value)"
    ></SidebarItem>
    <Divider class="sidebar-divider" :class="{ 'mx-3': showSidebar }"></Divider>
    <div
      v-if="showSidebar && favouriteProjectsPages.length"
      class="sidebar-title"
    >
      Favourite projects
    </div>
    <template v-if="favouriteProjectsPages.length">
      <SidebarItem
        v-for="page of favouriteProjectsPages"
        :key="page.value + page.id"
        :selectedPage="selectedPage"
        :page-details="page"
        :open="showSidebar"
        :link="getLinkData(page.value, page.id)"
        @click="onClickSidebarItem(page.value, page.id)"
      ></SidebarItem>
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import Sidebar from '@/components/common/sidebar/Sidebar.vue'
import SidebarItem from '@/components/common/sidebar/SidebarItem.vue'
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue'
import Divider from 'primevue/divider/Divider.vue'
import { takeLast } from 'ramda'
import { useRoute, LocationQueryRaw } from 'vue-router'
import { State, useStore } from '@/store'
import CompaniesPopup from '@/components/companies/CompaniesPopup.vue'
import { Store } from 'vuex'
import { USER_ROLES, SIDEBAR_PAGES } from '@/constants/constants'
import usePageFilters from '@/utils/hooks/useFilters'
import { Project } from '@/store/modules/admin/admin'

const store: Store<State> = useStore()
const route = useRoute()
const selectedPage = ref(route.path)
const clickOnHeaderEvent = ref(null)
const favouriteProjectsPages = computed(() => {
  return store.getters['admin/favouriteProjects'].map((project: Project) => ({
    icon: 'pi-stop',
    title: project.name,
    value: 'projects',
    id: project.id,
    image: project.image,
    favourite_project_selected:
      route.path.includes('projects') &&
      route.query.projects &&
      (+route.query.projects as number) === project.id,
  }))
})

const { pageFilters } = usePageFilters()
const showSidebar = computed(() => store.state.sidebar.showSidebar)
const user = computed(() => {
  return store.state.user.currentUser
})

const company = computed(() => store.state.company.selectedCompany)
const companyIdFromRoute = computed(
  () => store.getters['company/selectedCompanyId']
)

const showAdminDashboard = computed(() =>
  [USER_ROLES.ADMIN, USER_ROLES.OWNER].includes(
    store.getters['company/userRole']
  )
)

const toggleSidebar = () => {
  store.commit('sidebar/toggleSidebarValue')
}

const getLinkData = (page: string, favouriteProjectId?: number) => {
  let query
  const path = `/company/${companyIdFromRoute.value}/${page}`
  if (favouriteProjectId) {
    query = { projects: [favouriteProjectId] }
  } else {
    query = pageFilters(page).value
  }
  return {
    path,
    query: query as LocationQueryRaw,
  }
}

const onClickSidebarItem = async (
  page: string,
  favouriteProjectId?: number
) => {
  selectedPage.value = page
  if (favouriteProjectId) {
    await store.dispatch('filters/saveProjectFilters', {
      projects: [favouriteProjectId],
    })
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  .sidebar-divider {
    @apply my-2 w-auto;
  }

  .sidebar-title {
    transition: 0.3s ease-out;
    @apply px-4 py-3 uppercase text-left text-sm font-semi-bold tracking-sm text-gray-600;
  }
}

.v-enter-active,
.v-leave-active {
  opacity: 1;
  transition: opacity 1.3s ease;
}

.v-enter-from,
.v-leave-to {
  transition: opacity 0.1s ease;
  opacity: 0;
}
</style>
