<template>
  <ChartWrapper
    :title="chartTitle[chartTypes.BudgetPlannedVsUsed]"
    :loading="loading"
    :fetch-error="fetchError"
    :tooltip="chartExplanations[chartTypes.BudgetPlannedVsUsed]"
    :is-chart-data-empty="isChartDataEmpty"
    :type="chartTypes.BudgetPlannedVsUsed"
    :icons="['jira', 'tempo']"
  >
    <BasicChart
      :data="budgetPlannedVsUsedData"
      :options="budgetPlannedVsUsedOptions"
      type="line"
      :chart-name="chartTypes.BudgetPlannedVsUsed"
    ></BasicChart>
  </ChartWrapper>
</template>

<script setup lang="ts">
import ChartWrapper from '@/components/charts/ChartWrapper.vue'
import BasicChart from '@/components/charts/BasicChart.vue'
import { Filters } from '@/store/modules/filters'
import { computed, defineProps } from 'vue'

import {
  chartExplanations,
  chartTitle,
  chartTypes,
} from '@/constants/charts/constants'
import { isEmpty, uniq, values } from 'ramda'
import { usedColors } from '@/constants/constants'
import { ChartEvent, ScriptableContext, TooltipItem } from 'chart.js'
import { Budget } from '@/store/modules/charts/budget-planned-vs-used'
import {
  generateTickForXAxisTimeCharts,
  generateTooltipTitleForXAxisTimeCharts,
  hideCursorOnLegendLeave,
  showCursorOnLegendHover,
  verticalAnnotation,
} from '@/utils/chart-utils'
import useGettingChartData from '@/utils/hooks/useGettingChartData'
import { format } from 'date-fns'

const props = defineProps<{
  filters: Filters
  projectPage?: boolean
  milestonePopup?: boolean
}>()

const {
  response: chartData,
  loading,
  fetchError,
} = useGettingChartData(props, chartTypes.BudgetPlannedVsUsed)

const isChartDataEmpty = computed(() => {
  return values(chartData.value).every((field) => isEmpty(field))
})

const sortedLabels = computed(() =>
  uniq(
    values(chartData.value)
      .map((item) => item.map((data: Budget) => data.date))
      .flat()
      .sort()
  )
)

const annotations = computed(() => {
  const annotations = {}
  if (props.milestonePopup) {
    annotations.current_date = verticalAnnotation({
      color: 'black',
      label: '',
      value: sortedLabels.value[sortedLabels.value.length - 1],
      display: true,
      borderDash: [5, 5],
      hideTooltip: true,
    })
  }
  return annotations
})

const budgetPlannedVsUsedData = computed(() => ({
  labels: sortedLabels.value,
  datasets: [
    {
      label: 'Budget',
      key: 'budget',
      data: chartData.value?.budget,
      parsing: {
        yAxisKey: 'budget',
        xAxisKey: 'date',
      },
      backgroundColor: usedColors['danger-500'],
      borderColor: usedColors['danger-500'],
      borderWidth: 2,
      stepped: true,
      pointRadius: function (context: ScriptableContext<any>): number {
        const raw = context.raw as Budget
        return raw && raw.hideDot ? 0 : 3
      },
      pointHoverRadius: function (context: ScriptableContext<any>): number {
        const raw = context.raw as Budget
        return raw && raw.hideDot ? 0 : 3
      },
    },
    {
      label: 'Planned',
      key: 'planned_budget',
      data: chartData.value?.planned_budget,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'budget',
      },
      backgroundColor: usedColors['info-300'],
      borderColor: usedColors['info-300'],
      borderRadius: 4,
      borderWidth: 2,
      pointRadius: function (context: any): number {
        return (props.milestonePopup &&
          (context.index === 0 ||
            context.index === chartData.value?.planned_budget.length - 1)) ||
          !props.milestonePopup
          ? 3
          : 0
      },
      hidden: !props.milestonePopup,
    },
    {
      label: 'Used',
      key: 'used_budget',
      data: chartData.value?.used_budget,
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'budget',
      },
      backgroundColor: '#4CD990A5',
      borderColor: usedColors['success-400'],
      borderRadius: 4,
      pointRadius: function (context: any): number {
        return (props.milestonePopup &&
          (context.index === 0 ||
            context.index === chartData.value?.used_budget.length - 1)) ||
          !props.milestonePopup
          ? 3
          : 0
      },
      borderWidth: 2,
      fill: true,
    },
  ],
}))

const budgetPlannedVsUsedOptions = computed(() => ({
  type: 'line',
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      position: 'bottom',
      grid: { drawOnChartArea: false },
      ticks: {
        callback: function (val: number, context, ticks): string {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dateValue = this.getLabelForValue(val)
          if (props.milestonePopup) {
            const formattedDate = format(new Date(dateValue), 'dd MMM, yyyy')

            if (val === 0 || val === ticks.length - 1) {
              return formattedDate
            }
            return ''
          } else {
            if (dateValue.split(' ').length > 1) return ''
            return generateTickForXAxisTimeCharts(
              dateValue,
              props.filters.scale_type
            )
          }
        },
        autoSkip: !props.milestonePopup,
        maxTicksLimit: props.milestonePopup ? 0 : false,
        maxRotation: props.milestonePopup ? 0 : 50,
      },
    },
    y: {
      grid: { drawOnChartArea: false },
      ticks: {
        callback: (val: number): string =>
          new Intl.NumberFormat('de-CH', {
            style: 'currency',
            currency: 'CHF',
            minimumFractionDigits: 0,
          }).format(val),
      },
    },
  },
  indexAxis: 'x',
  interaction: {
    mode: 'nearest',
    intersect: false,
  },
  plugins: {
    datalabels: null,
    tooltip: {
      mode: 'nearest',
      callbacks: {
        title: function (context: TooltipItem<any>[]): string {
          const label = context[0].label
          return generateTooltipTitleForXAxisTimeCharts(
            label,
            props.filters.scale_type
          )
        },
        label: function (context: TooltipItem<any>): string {
          const { label } = context.dataset
          return `${label}: ${new Intl.NumberFormat('de-CH', {
            style: 'currency',
            currency: 'CHF',
            minimumFractionDigits: 0,
          }).format(context.parsed.y)}`
        },
      },
    },
    legend: {
      onHover: (evt: ChartEvent): void => showCursorOnLegendHover(evt),
      onLeave: (evt: ChartEvent): void => hideCursorOnLegendLeave(evt),
      align: 'start',
      labels: {
        borderRadius: 4,
        boxWidth: 10,
        boxHeight: 10,
        generateLabels(chart) {
          return chart.data.datasets.map((dataset, i) => ({
            datasetIndex: i,
            text: !props.milestonePopup
              ? dataset.label
              : `${dataset.label}: ${new Intl.NumberFormat('de-CH', {
                  style: 'currency',
                  currency: 'CHF',
                  minimumFractionDigits: 0,
                }).format(dataset.data[dataset.data.length - 1].budget)}`,
            fillStyle: dataset.backgroundColor,
            strokeStyle: dataset.backgroundColor,
            hidden: !chart.isDatasetVisible(i),
          }))
        },
      },
    },
    annotation: {
      annotations: annotations.value,
    },
  },
}))
</script>
