import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import PortfolioDashboard from '../views/PortfolioDashboard.vue'
import ProjectDashboard from '../views/ProjectDashboard.vue'
import TeamDashboard from '../views/TeamDashboard.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import Companies from '../views/Companies.vue'
import CompanyCreation from '../views/CompanyCreation.vue'
import { authService } from '@/services/auth'
import { store } from '@/store'

const selectedCompany = window.localStorage.getItem('selectedCompany')
const selectedCompanyIdFromLocalStorage = selectedCompany
  ? JSON.parse(selectedCompany).id
  : null

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: store.getters['company/selectedCompanyId']
      ? `/company/${store.getters['company/selectedCompanyId']}/portfolio`
      : selectedCompanyIdFromLocalStorage
      ? `/company/${selectedCompanyIdFromLocalStorage}/portfolio`
      : '/companies',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company-creation',
    name: 'company-creation',
    component: CompanyCreation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/portfolio',
    name: 'portfolio',
    component: PortfolioDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/team',
    name: 'team',
    component: TeamDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/projects',
    name: 'projects',
    component: ProjectDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/administration',
    name: 'administration',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    await store.dispatch('globalLoading/startGlobalLoading')
  }
  if (to.meta.requiresAuth && !(await authService.authenticated())) {
    next('/login')
  } else {
    if (to.params.id) {
      await store.dispatch(
        'company/takeIdFromRouteAndSaveCompanyInLocalStorage',
        +to.params.id
      )
      if (to.name === 'projects') {
        await store.dispatch('filters/saveProjectFilters', to.query)
      } else if (to.name === 'portfolio') {
        await store.dispatch('filters/savePortfolioFilters', to.query)
      } else if (to.name === 'team') {
        await store.dispatch('filters/saveTeamFilters', to.query)
      }
    }
    next()
  }
})
router.afterEach(async () => {
  await store.dispatch('globalLoading/finishGlobalLoading')
})
export default router
